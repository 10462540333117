import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import Overview from "../components/Overview";

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<h1>Under construction</h1>
			{/* <Overview type="Photo" projects={data.allContentfulProject.nodes} /> */}
		</Layout>
	);
};

export const query = graphql`
    {
        allContentfulProject {
            nodes {
                name
                photos {
                    file {
                        fileName
                    }
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: TRACED_SVG
                                formats: [JPG, WEBP, AVIF]
                                outputPixelDensities: [0.1, 0.25, 0.5, 1, 2]
                                width: 400
                                transformOptions: { fit: COVER, cropFocus: CENTER }
                                jpgOptions: { quality: 60 }
                                avifOptions: { quality: 50 }
                                webpOptions: { quality: 64 }
                            )
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
